import { useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useRecoilState } from "recoil";
import * as yup from "yup";

import useToast from "@/hooks/useToast";
import { locationState } from "@/stores/atoms/locationAtom";
import { userSelector } from "@/stores/selectors/userSelector";
import { User, SendingAgreement } from "@/types/user";
import { apiPost } from "@/utils/api";

interface Props {
  cancel?: () => void;
  profileSubmit?: () => void;
}

export const EditSendingAgreement = (props: Props) => {
  const [user, setUser] = useRecoilState(userSelector);
  const [, setKey] = useRecoilState(locationState);
  const { error, success } = useToast();
  const [conditions, setSendingAgreement] = useState<SendingAgreement>({
    sending_agreement: user.sending_agreement,
  });

  const schema = yup.object({
    sending_agreement: yup.boolean().required(),
  });

  const {
    setValue,
    formState: { errors },
    watch,
  } = useForm<{ sending_agreement: boolean }>({
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues: {
      sending_agreement: user.sending_agreement as boolean,
    },
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSendingAgreement((prevValue) => ({
      ...prevValue,
      sending_agreement: event.target.checked,
    }));
  };

  const handleSubmit = async () => {
    const res = await apiPost("/api/user/sending-agreement", { sending_agreement: conditions.sending_agreement });

    if (res.code === 422 && res.errors) return error("登録に失敗しました");

    setUser((prevValue) => ({
      ...prevValue,
      sending_agreement: conditions.sending_agreement,
    }));

    success("グループ会社への情報共有の同意を保存しました");
  };

  useEffect(() => {
    conditions.sending_agreement !== user.sending_agreement && handleSubmit();
  }, [conditions]);

  return (
    <div className='justify-center md:w-full pb-4'>
      <span className="text-[14px]">弊社<a className="text-blue-800 hover:underline" href="https://job.kasooku.jp/UN_group" target="_blank" rel="noopener noreferrer">グループ会社</a>でも有力・有望な成長企業の転職求人のご紹介が可能です。</span>
      <div className='flex items-center mt-2'>
        <input
          type='checkbox'
          id="check"
          className='mr-2 mt-2 h-5 w-5 rounded-md border border-gray-200 md:mt-0'
          onChange={handleChange}
          checked={conditions.sending_agreement}
        />
        <label htmlFor='check' className='text-[14px] md:mt-[1px]'>弊社グループ会社へ登録情報を共有して求人情報を受け取る</label>
      </div>
    </div>
  );
};

export default EditSendingAgreement;