import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { useRecoilState } from "recoil";
import * as yup from "yup";

import useToast from "@/hooks/useToast";
import { locationState } from "@/stores/atoms/locationAtom";
import { apiPost } from "@/utils/api";

interface Nda {
  [key: string]: string;
}

function NdaAgreePage() {
  const navigate = useNavigate();
  const { error } = useToast();
  const [, setKey] = useRecoilState(locationState);
  const [isChecked, setIsChecked] = useState(false);

  const schema = yup.object({
    name: yup.string().required("氏名は必須入力項目です"),
    company: yup.string().required("会社名は必須入力項目です"),
    email: yup
      .string()
      .required("メールアドレスは必須入力項目です")
      .email("有効なメールアドレスを入力してください")
      .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "有効なメールアドレスを入力してください"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string; company: string; name: string }>({
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<Nda> = async (params) => {
    const res = await apiPost("/api/nda-agreement", params);
    if (res.code === 200) {
      navigate({
        pathname: "/nda/complete",
      });
    } else {
      error("秘密保持約款の同意に失敗しました");
    }
  };

  useEffect(() => {
    setKey((prevValue) => ({ ...prevValue, component: "footer" }));
  }, []);

  return (
    <div className='overflow-x-hidden bg-blue-50 sm:mt-4 md:mt-0 '>
      <div className='rounded-lg bg-white py-8 sm:mx-4 sm:my-10 sm:px-8 sm:text-[14px] md:mx-40 md:my-10 md:px-16 md:text-[16px]'>
        <div>
          <p className='text-[12px] font-bold text-gray-500'>
            制定：2023年12月6日
            <br />
            <br />
          </p>
          <p className='text-[28px] font-bold'>Kasooku秘密保持約款</p>
          <p className='mb-8 border-b border-gray-200 pb-8'>
            Kasooku秘密保持約款（以下「本約款」という。）は、カソーク株式会社の提供するサービス「Kasooku」（利用者の提示した案件を受託する副業委託先を紹介するサービス）の利用（以下「本目的」という。）のために、利用者とカソーク株式会社の間で相互に開示される情報に適用される。
          </p>
          <p className='mb-4 text-[18px] font-bold'>第1条（秘密情報）</p>
          本約款において｢秘密情報｣とは、利用者とカソーク株式会社の間で本目的の実施のために開示される一切の情報のうち、開示の際に秘密である旨が明示された情報、並びに、情報の性質及び開示時の状況から合理的に秘密と認められる情報をいう。秘密情報を開示した者を「開示者」といい、開示を受けた者を「受領者」という。ただし、次の各号に該当する情報は秘密情報に含まれない。
          <p>(1) 開示の時点で受領者が既に了知していたもの</p>
          <p>(2) 開示の時点で既に公知のもの、又は、開示後に受領者の責めによらずして公知となった情報</p>
          <p>(3) 受領者が正当な権限を有する第三者から秘密保持義務を伴わずに入手したもの</p>
          <p>(4) 受領者が秘密情報を使用することなく独自に開発したもの</p>
          <p className='mb-4 mt-8 text-[18px] font-bold'>第2条（秘密保持義務）</p>
          <p>
            (1)
            受領者は、事前に開示者の承諾を得ることなく、秘密情報を第三者に開示又は漏洩してはならない。ただし、受領者は、本目的のため必要な範囲において、自己又は関係会社の役員及び従業員、再委託先、並びに自己が依頼した弁護士、会計士その他のアドバイザー（以下「開示先」という。）に対して秘密情報を開示できる。この場合、当該開示先に対して、本契約に基づき自己が負う秘密保持義務と同等以上の義務を課さなければならず、開示先が当該義務に違反した場合、受領者が本契約上の秘密保持義務に違反したものとみなす。
          </p>
          <p>
            (2)
            第1項の規定に拘わらず、受領者が法令又は権限を有する公的機関の命令等により開示を求められた場合、受領者は必要最小限の範囲内で秘密情報を開示することができる。
          </p>
          <p>(3) 受領者は、秘密情報を本目的以外に利用してはならない。</p>
          <p className='mb-4 mt-8 text-[18px] font-bold'>第3条（事故発生時の対応）</p>
          <p>
            受領者が本約款に違反し若しくは秘密情報の漏洩等の事故が生じた場合、又は、それらのおそれがある場合、受領者は開示者に対し、直ちにその旨及び詳細を報告するものとする。この場合において、受領者は、開示者の指示に従い、秘密情報の回収等の適切な処置を講ずるとともに、秘密情報の漏洩を最小限に留めるよう最善の措置を講じるものとする。
          </p>
          <p className='mb-4 mt-8 text-[18px] font-bold'>第4条（有効期間）</p>
          <p>(1) 本約款の有効期間は、利用者が最後に案件を提示した日から1年間とする。</p>
          <p>
            (2)
            本約款が終了した場合、又は、開示者より請求を受けた場合、受領者は、直ちに開示者より開示された秘密情報等を廃棄、消去し、又は開示者へ返却しなければならない。
          </p>
          <p className='mb-4 mt-8 text-[18px] font-bold'>第5条（損害賠償）</p>
          <p>
            受領者は、本約款に定める義務に違反したことにより開示者に損害を与えた場合には、開示者が被った一切の損害を賠償する責任を負わなければならない。
          </p>
          <p className='mb-4 mt-8 text-[18px] font-bold'>第6条（裁判管轄）</p>
          <p>
            本約款に基づき又は関連して生じる一切の紛争に関しては、東京地方裁判所を第一審の専属的合意管轄裁判所とする。
          </p>
          <p className='w-full pr-4 text-right'>以上</p>
          <p className='mt-8 w-full pr-4 text-right text-[12px] font-bold text-gray-500'>
            カソーク株式会社
            <br />
            2023年12月6日制定
          </p>
        </div>
        <div className='mb-28'>
          <div className='mt-8 h-[30px] w-full text-center'>
            <div className='flex items-center justify-center'>
              <p className='w-1/3 text-left md:w-1/6'>氏名：</p>
              <input
                type='text'
                className='border-grey-light w-2/3 rounded border p-1 md:w-1/4'
                {...register("name")}
              />
            </div>
            <span className='absolute ml-[-32px] mt-1 text-xs text-red-500'>{errors.name?.message}</span>
          </div>
          <div className='mt-8 h-[30px] w-full text-center'>
            <div className='flex items-center justify-center'>
              <p className='w-1/3 text-left md:w-1/6'>会社名：</p>
              <input
                type='text'
                className='border-grey-light w-2/3 rounded border p-1 md:w-1/4'
                {...register("company")}
              />
            </div>
            <span className='absolute ml-[-32px] mt-1 text-xs text-red-500'>{errors.company?.message}</span>
          </div>
          <div className='mt-8 h-[30px] w-full text-center'>
            <div className='flex items-center justify-center'>
              <p className='w-1/3 text-left md:w-1/6'>メール：</p>
              <input
                type='text'
                className='border-grey-light w-2/3 rounded border p-1 md:w-1/4'
                {...register("email")}
              />
            </div>
            <span className='absolute ml-[-32px] mt-1 text-xs text-red-500'>{errors.email?.message}</span>
          </div>
          <div className='mt-8 h-[30px] w-full text-center'>
            <div className='flex justify-end'>
              <p className='w-0 text-left md:md:w-1/6 md:w-1/3'></p>
              <div className='my-4 flex w-full md:w-2/3'>
                <div className='w-fit'>
                  <input
                    type='checkbox'
                    className='border-grey-light mr-2 mt-3 w-fit rounded border md:mt-0'
                    onChange={() => (isChecked ? setIsChecked(false) : setIsChecked(true))}
                  />
                </div>
                <p className='w-full text-left text-[14px] md:pt-[1.5px]'>
                  上記の内容をよく読んで内容をご理解いただけましたか
                </p>
              </div>
            </div>
            <button
              type='submit'
              className={`mt-4  h-[40px] w-[144px] cursor-pointer rounded-[4px] bg-blue-700 py-2 text-center font-bold leading-5 text-white hover:bg-blue-500 ${
                !isChecked && "bg-gray-500 hover:bg-gray-500"
              }`}
              onClick={handleSubmit(onSubmit)}
              disabled={!isChecked}
            >
              同意する
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NdaAgreePage;
